import React from "react";

export default ({ color }) => (
  <svg viewBox="0 0 26 28">
    <path
      fill={color}
      d="M25.8,24.1c0.1-0.1,0.2-0.2,0.2-0.4v-7.7c0-0.2-0.1-0.3-0.2-0.4l-6-3.7V4.4c0-0.2-0.1-0.3-0.2-0.4l-6.3-3.9
	c-0.2-0.1-0.4-0.1-0.5,0L6.5,3.9C6.4,4,6.3,4.2,6.3,4.4v7.4l-6,3.7C0.1,15.6,0,15.7,0,15.9v7.7c0,0.2,0.1,0.3,0.2,0.4l6.3,3.8
	c0.2,0.1,0.4,0.1,0.5,0l6-3.7l6,3.7c0.2,0.1,0.4,0.1,0.5,0L25.8,24.1z M12.6,23.3l-5.8,3.5L1,23.3v-7.1l5.8-3.5l5.8,3.5V23.3z
	 M7.2,11.8V4.6L13,1.1l5.8,3.5v7.2L13,15.3L7.2,11.8z M13.5,23.3v-7.1l5.8-3.5l5.8,3.5v7.2l-5.8,3.5L13.5,23.3z"
    />
  </svg>
);
